export function Logo() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 327 327"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 48C0 21.4903 21.4903 0 48 0H279C305.51 0 327 21.4903 327 48V315C327 321.627 321.627 327 315 327H48C21.4903 327 0 305.51 0 279V48Z"
        fill="#D54AA1"
      ></path>
      <path
        d="M78.6 286.75C67.6333 286.75 59.2333 283.95 53.4 278.35C47.8 272.517 45 264.233 45 253.5V73.25C45 62.5167 47.8 54.35 53.4 48.75C59.2333 42.9167 67.6333 40 78.6 40H147.2C190.133 40 223.267 50.7333 246.6 72.2C270.167 93.6667 281.95 124 281.95 163.2C281.95 182.8 278.917 200.3 272.85 215.7C266.783 230.867 258.033 243.817 246.6 254.55C235.167 265.05 221.05 273.1 204.25 278.7C187.683 284.067 168.667 286.75 147.2 286.75H78.6ZM109.4 234.6H143C155.367 234.6 165.983 233.083 174.85 230.05C183.95 227.017 191.417 222.583 197.25 216.75C203.317 210.917 207.75 203.567 210.55 194.7C213.583 185.833 215.1 175.333 215.1 163.2C215.1 138.933 209.15 121.083 197.25 109.65C185.35 97.9833 167.267 92.15 143 92.15H109.4V234.6Z"
        fill="#FAFAF9"
      ></path>
      <line
        x1="24"
        y1="140.322"
        x2="145.322"
        y2="19"
        stroke="#D54AA1"
        strokeWidth="25"
        strokeLinecap="round"
      ></line>
      <circle cx="281.5" cy="286.5" r="21.5" fill="#FAFAF9"></circle>
    </svg>
  )
}
